<template>

  <!-- BODY -->
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <!-- Form -->
    <b-form
      class="p-2"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.identifier"
            rules="required"
            label="Identificador"
            placeholder="informe o identificador do dispositivo"
            name="identifier"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <i2-form-input
            v-model="model.address"
            rules="required"
            label="Ip do Dispositivo"
            placeholder="Ip do Dispositivo"
            name="address"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <i2-divider
            text="Configurações"
            align="left"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <i2-form-input
            v-model="model.interval"
            rules="required|integer|min_value:1"
            label="Intervalo de Leitura (m)"
            placeholder="Intervalo de Leitura em minutos"
            name="interval"
          />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <i2-form-input
            v-model="model.range"
            rules="required|integer|min_value:1"
            label="Range da Sonda (m)"
            placeholder="Valor em metros para o range da sonda"
            name="range"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <i2-form-input
            v-model="model.hidro"
            rules="required|integer|min_value:1"
            label="Pulso do Hidrometro (L)"
            placeholder="Valor em litros para o pulso do hidrometro"
            name="hidro"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <i2-form-input
            v-model="model.chain"
            rules="required|integer|min_value:1"
            label="Alarme corrente alta (A)"
            placeholder="Valor em Amperes para o alarme de corrente alta da bomba"
            name="chain"
          />
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <i2-form-input
            v-model="model.chain_down"
            rules="required|integer|min_value:1"
            label="Alarme corrente baixa (B)"
            placeholder="Valor em Amperes para o alarme de corrente baixa da bomba"
            name="chain_down"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <i2-form-text-area
            v-model="model.details"
            label="Observações"
            placeholder="Observações gerais para o dispositivo"
            name="details"
            rows="3"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <i2-divider />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          class="text-right"
        >
          <!-- Form Actions -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >

            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2Divider from '@/components/i2/content/i2Divider.vue'
import I2FormTextArea from '@/components/i2/forms/elements/I2FormTextArea.vue'

export default {
  components: {
    I2FormTextArea,
    I2Divider,
    I2FormInput,
    // Form Validation
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    deviceList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      model: {
        identifier: '',
        uuid: null,
        address: '192.168.1.1',
        interval: 1,
        range: 1,
        hidro: 1,
        chain: 1,
        chain_down: 1,
        details: '',
      },
      blankModel: {
        identifier: '',
        uuid: null,
        address: '192.168.1.1',
        interval: 1,
        range: 1,
        hidro: 1,
        chain: 1,
        chain_down: 1,
        details: '',
      },
    }
  },
  watch: {
    isAddNewUserSidebarActive(data) {
      this.active = data
    },
    device(data) {
      this.model = data
      if (!data.uuid) {
        this.model = { ...this.blankModel }
        this.$refs.refFormObserver.reset()
      }
    },
  },
  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (this.deviceList.find(device => device.identifier === this.model.identifier && device.uuid !== this.model.uuid)) {
          this.$refs.refFormObserver.setErrors({
            Identificador: `Já encontramos um dispositivo cadastrado com o identificador ${this.model.identifier}`,
          })
          return false
        }

        if (success) {
          let response = {}
          if (this.model.uuid) {
            response = await this.$http.put(`device/${this.model.uuid}`, this.model)
          } else {
            response = await this.$http.post('device', this.model)
          }
          if (response.error) {
            this.$notify.error(response.error_message)
            return false
          }
          this.$notify.success('Dispositivo salvo com sucesso.')
          this.$emit('onSave', response)
          this.model = { ...this.blankModel }
          return true
        }
        return true
      })
    },
  },
}
</script>
