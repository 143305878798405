<template>
  <b-card-code>
    <b-row>
      <b-col
        class="text-right"
      >
        <b-button
          variant="primary"
          @click="isActivated = true"
        >
          <FeatherIcon icon="PlusIcon" />
          <span class="text-nowrap">Adicionar Dispositivo</span>
        </b-button>
      </b-col>
    </b-row>

    <i2-side-bar
      :is-active.sync="isActivated"
      window-title="Dispositivos"
    >
      <device-form
        :device="currentModel"
        :device-list="items"
        @onSave="onSave()"
      />
    </i2-side-bar>

    <Grid
      :fields="fields"
      :items="items"
      edit-button-label="edit"
      new-button-label="new"
    >
      <template #cell(data)="data">
        <b-col>
          <b>Intervalo Leitura: </b>{{ data.item.data.interval }}m
        </b-col>
        <b-col>
          <b>Range da Sonda: </b>{{ data.item.data.range }}m
        </b-col>
        <b-col>
          <b>Pulso: </b>{{ data.item.data.hidro }}l
        </b-col>
        <b-col>
          <b>Alarme de Corrente Alta: </b>{{ data.item.data.chain }}A
          <br><b>Alarme de Corrente Baixa: </b>{{ data.item.data.chain_down }}A
        </b-col>
      </template>
      <template #cell(is_in_use)="data">
        <b-badge
          :variant="data.item.is_in_use ? 'success' : 'danger'"
        >
          {{ data.item.is_in_use ? 'Sim' : 'Não' }}
        </b-badge>
        <span v-if="data.item.is_in_use">
          <br>
          <b>Empresa: </b><br> {{ data.item.company.name }}
          <br>
          <b>Unidade: </b><br> {{ data.item.unity.name }}
        </span>
      </template>
      <template
        #cell(action)="data"
        style="width: 10%"
      >
        <div class="px-4 text-center">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="setCurrentModel(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="data.item.is_in_use"
              @click="deleteDevice(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </Grid>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import DeviceForm from '@/views/devices/DeviceForm.vue'
import Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    I2SideBar,
    Grid,
    BCardCode,
    DeviceForm,
  },
  data() {
    return {
      fields: [
        {
          key: 'identifier',
          label: 'Identificador',
          class: 'w-2',
          stickyColumn: true,
        },
        {
          key: 'address',
          label: 'ip',
          class: 'w-2',
        },
        {
          key: 'data',
          label: 'Configurações',
          class: 'w-2',
        },
        {
          key: 'is_in_use',
          label: 'Em uso',
          class: 'w-2',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10',
        },
      ],
      items: [],
      currentModel: {},
      isActivated: false,
    }
  },
  watch: {
    isActivated(val) {
      if (!val) {
        this.currentModel = {}
      }
    },
  },
  mounted() {
    this.getDevices()
  },
  methods: {
    async getDevices() {
      const response = await this.$http.get('device')
      this.items = response
    },
    setCurrentModel(model) {
      this.currentModel = { ...model }
      this.isActivated = true
    },
    onSave() {
      this.currentModel = {}
      this.isActivated = false
      this.getDevices()
    },
    deleteDevice(device) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme a exclusão do dispositivo.', {
          title: 'Confirm exclusão?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            const response = await this.$http.delete(`device/${device.uuid}`)
            if (response.error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.error_message,
                  icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `O dispositivo ${device.identifier} foi removido com sucesso.`,
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.getDevices()
            }
          }
          return true
        })
    },
  },
}
</script>
<style>
th.w-1 {
  width: 50%;
}
th.w-2 {
  width: 20%;
}
th.w-3 {
  width: 10%;
}
th.w-10 {
  width: 20%;
  text-wrap: none;
}
</style>
